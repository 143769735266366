import React from "react";
import { graphql } from "gatsby"; // Link
import { v4 as uuidv4 } from "uuid";

// Import utils
import findTalkDateAndTime from "../utils/dateUtil";
import { getBlockContent, getBlockImage } from "../utils/helpers";
import { superEvent } from "../utils/superEvent";

// Layout
import Layout from "../components/layout/Layout";
import SpeakerHero from "../components/layout/SpeakerHero";

// Primitives
import EmojiIcon from "../components/primitives/EmojiIcon";
import Grid from "../components/primitives/Grid";
// import WrapRotation from "../components/primitives/WrapRotation";
import Button from "../components/primitives/Button";

// Containers
import RenderRichContent from "../components/containers/RenderRichContent";

const Workshop = ({ data }) => {
  const { sanityWorkshop = {} } = data;

  const {
    duration,
    time,
    lead,
    title,
    sceneRef,
    _rawDescription,
    foredragsholdere,
    metaInfo,
    slug,
  } = sanityWorkshop;

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader,
    description:
      metaInfo?.description || lead || getBlockContent([_rawDescription]),
    fullSlug: `workshop/${slug?.current}`,
    image:
      metaInfo?.image ||
      getBlockImage([_rawDescription])?.image ||
      foredragsholdere?.[0]?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage([_rawDescription])?.alt,
  };

  const pageSchemas = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: metaInfo?.header || title || "",
    description:
      metaInfo?.description || lead || getBlockContent([_rawDescription]) || "",
    location: {
      "@type": "VirtualLocation",
      name: "Streamed online by invite",
      url: "https://www.y-oslo.com/",
    },
    eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    eventStatus: "https://schema.org/EventMovedOnline",
    offers: {
      "@type": "Offer",
      url: "https://www.y-oslo.com/tickets",
      price: sanityWorkshop?.price || "1900",
      priceCurrency: "NOK",
      availability: sanityWorkshop?.full
        ? "https://schema.org/SoldOut"
        : "https://schema.org/OnlineOnly",
      validFrom: new Date("2020-03-04"),
    },
    startDate: time,
    endDate:
      time && duration
        ? new Date(new Date(time).getTime() + duration * 60000)
        : time,
    organizer: {
      "@type": "Organization",
      name: "Netlife Design",
      url: "https://www.netlife.com/",
    },
    duration: `PT${duration}M`,
    url: `https://www.y-oslo.com/workshop/${slug?.current}`,
    image: {
      "@type": "ImageObject",
      contentUrl:
        metaInfo?.image?.asset?.url ||
        getBlockImage([_rawDescription])?.image?.asset?.url ||
        foredragsholdere?.[0]?.image?.asset?.url ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
      url:
        metaInfo?.image?.asset?.url ||
        getBlockImage([_rawDescription])?.image?.asset?.url ||
        foredragsholdere?.[0]?.image?.asset?.url ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
    },
    performer:
      foredragsholdere.map(person => {
        return {
          "@type": "Person",
          name: person?.name ?? "",
          url: `https://www.y-oslo.com/speaker/${person?.slug?.current}`,
          image: person?.image?.asset?.url ?? "",
        };
      }) ?? "",
    superEvent: superEvent,
  };

  return (
    <Layout metaInfo={metaStructure} pageSchemas={pageSchemas}>
      {/* Workshop speaker */}
      {/* Name of the talk, time, speaker image, speaker name */}
      {foredragsholdere.length === 1 && (
        <SpeakerHero
          person={foredragsholdere[0]}
          talktitle={true}
          talksData={sanityWorkshop}
          title={title}
          heroType="workshop"
          details={true}
          time={findTalkDateAndTime(time, duration)}
          stage={sceneRef?.title || ""}
        />
      )}

      {/* <p>{JSON.stringify(workshop)}</p> */}

      {/* <SpeakerHero
        person={foredragsholdere}
        talktitle={true}
        title={title}
        details={true}
        time={findTalkDateAndTime(time, duration)}
        stage={sceneRef?.title || ""}
      /> */}

      {/* Meta info */}
      {/* <div className="mb-8 font-bold text-lg uppercase"> */}
      {/* Location */}
      {/* <span className="block">@ {sceneRef?.title}</span> */}
      {/* Date / time. TODO: @hanna hook up to sanity */}
      {/* <span className="block">@ oct 21. 10.00—10.45 (sanity)</span> */}
      {/* Talk language. TODO: @hanna hook up to sanity */}
      {/* <span className="block">@ NO (sanity)</span> */}
      {/* Topic. TODO: @hanna hook up to sanity */}
      {/* <span className="block">@ strategy (sanity)</span> */}
      {/* Duration */}
      {/* <p className="text-lg">
          {time} + {duration}min
        </p> */}
      {/* </div> */}

      <Grid>
        <div className="col-span-12 md:col-span-7">
          {/* Workshop description */}
          {lead}
          <RenderRichContent content={_rawDescription} />

          {/* Centered ticket button */}
          <div className="flex justify-center mb-16">
            <Button color="green" size="lg" href="/tickets-y-workshop" arrows>
              Get your ticket
            </Button>
          </div>

          {/* SPEAKERS */}
          {foredragsholdere.map(({ name, _rawBio, image, shoutOut, slug }) => (
            <div className="" key={uuidv4()}>
              <h3 className="text-xl uppercase">
                About {name}{" "}
                <EmojiIcon name="leadership" animated className="ml-1" />
              </h3>
              <div className="">
                <div data-header-speaker={name}>
                  <p className="netlife-sans">{shoutOut}</p>

                  <RenderRichContent content={_rawBio} />

                  {/* SOME buttons. TODO: FIX!! */}
                  {/* {foredragsholdere?.socialMedia?.linkedIn && (
                    <div className="flex justify-center mb-2">
                      <Button
                        className="-translate-x-1/3"
                        color="green"
                        size="xl"
                        href={foredragsholdere?.socialMedia?.linkedIn}
                      >
                        LinkedIn
                      </Button>
                    </div>
                  )}

                  {foredragsholdere?.socialMedia?.twitter && (
                    <div className="flex justify-center">
                      <Button
                        className="translate-x-1/3"
                        color="green"
                        size="xl"
                        href={foredragsholdere?.socialMedia?.twitter}
                      >
                        Twitter
                      </Button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Layout>
  );
};
export default Workshop;

export const query = graphql`
  query($id: String!) {
    sanityWorkshop(id: { eq: $id }) {
      id
      title
      lead
      _rawDescription(resolveReferences: { maxDepth: 10 })
      duration
      time
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
      foredragsholdere {
        company
        language
        image {
          asset {
            url
          }
        }
        shoutOut
        slug {
          current
        }
        stilling
        name
        socialMedia {
          twitter
          linkedIn
        }
        _rawBio(resolveReferences: { maxDepth: 10 })
      }
      sceneRef {
        title
        slug {
          current
        }
      }
      trackRef {
        title
      }
      full
      price
    }
  }
`;
